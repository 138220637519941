<template>
    <div style="background-color: rgba(45, 92, 91, 0.65); z-index: 10;">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand navbar-toggler" href="/" style="border: none;">
                    <img src="images/logo.png" height="50" alt="">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style="border-color: #fff;">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent" style="justify-content: space-between; background-color: rgba(45, 92, 91, 0.65);">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" style="font-size: 14px; color: #fff;" href="#">Home <span
                                    class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" style="font-size: 14px; color: #fff;" href="#about">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" style="font-size: 14px; color: #fff;" href="#tokenomics">Tokenomics</a>
                        </li>
                    </ul>
                    <div style="text-align: center;">
                        <button
                            style="padding: 10px; background: none; box-shadow: 0 0; border: 2px solid; border-radius: 15px; color: #fff; margin-right: 25px;">
                            <a class="navbar-brand" href="/dashboard"
                                style="color: #fff; margin-right: 0; font-size: 14px;">
                                Dashboard
                            </a>
                        </button>
                        <button
                            style="padding: 10px; background: #fff; box-shadow: 0 0; border: 2px solid; border-radius: 15px; color: #fff;">
                            <a class="navbar-brand" href="/map"
                                style="color: #2D5C5B; margin-right: 0; font-size: 14px;">Map</a>
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <div data-bs-spy="scroll" style="margin-top: 100px; padding-bottom: 100px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 home-section-1">
                    <div class="home-section-1-left-1">Welcome to</div>
                    <div class="home-section-1-left-2">Memecity</div>
                </div>
                <div class="col-lg-5" style="text-align: center;">
                    <img class="home-section-1-right" src="assets/images/logo.png" alt="">
                </div>
            </div>
        </div>
        <div class="container" id="about">
            <div class="home-section-2">
                <!-- <img class="home-section-2-element" style="position: absolute; z-index: 0;" :src="windowWidth < 992 ? 'assets/images/item-bg2.png' : 'assets/images/home-item-bg1.png'" alt=""> -->
                <img class="home-section-2-element" style="position: absolute; z-index: 0;"
                    :src="'assets/images/home-item-bg1.png'" alt="">
                <div class="home-section-2-element" style=" position: relative;z-index: 1; padding: 75px 50px;">
                    <div style="font-size: 22px; text-align: center; line-height: 35px; padding-bottom: 20px;">ABOUT</div>
                    <div style="font-size: 12px; text-align: justify;">
                        Welcome to Memecity, the bustling metropolis of the meme ecosystem built on the blockchain!
                        Memecity is not just a virtual space but a thriving community where users can immerse themselves in
                        the unique world of digital landscapes, decentralized finance, and, of course, memes.
                        In Memecity, users can leverage the power of Solana to acquire parcels of virtual lands, each
                        offering a canvas for creativity and economic potential.

                    </div>
                    <!-- <div style="font-size: 15px; text-align: justify;">
                    </div> -->
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-md-5" style="display: flex; justify-content: start; align-items: center;">
                    <img src="assets/images/home-section3-img-v2.png" style="width: 95%;" alt="">
                </div>
                <div class="col-md-7" style="padding-left: 35px;">
                    <div class="home-section-3-text-element">
                        <div style="color: #FFE5A5; padding-bottom: 15px;">Lands</div>
                        <p style="color: #fff; font-size: 12px;">
                            In Memecity, lands are dynamic canvases ranging from 1x1 to 8x8, each with unique potential.
                            Acquiring Lands grants users Farmers, the virtual workforce generating memes and MCC. There are
                            3 types of Lands: Soil, Water, and Tree.

                        </p>
                    </div>
                    <div class="home-section-3-text-element">
                        <div style="color: #FFE5A5; padding-bottom: 15px;">Farmers</div>
                        <p style="color: #fff; font-size: 12px;">
                            Farmers, the lifeblood of Memecity, produce memes and MCC. Different types of lands host
                            specialized workers: Farmer, Fisherman, or Forester. Upon acquiring lands, you receive Farmers,
                            with each 1x1 land having a maximum capacity of 5 Farmers.
                        </p>
                    </div>
                    <div class="home-section-3-text-element">
                        <div style="color: #FFE5A5; padding-bottom: 15px;">MCC</div>
                        <p style="color: #fff; font-size: 12px;">
                            MCC, Memecity's lifeblood, is a decentralized currency empowering the economy. Users trade MCC
                            for Solana, invest in Lands, or boost their Farmer count. MCC's value reflects Memecity's
                            creativity and economic vitality.
                        </p>

                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="margin-top: 150px;">
            <div style="font-size: 32px; color: #fff; text-align: center; padding-bottom: 100px;">HOW TO BUY</div>
            <div class="row" style="margin-bottom: 75px;">
                <div class="col-md-8" style="display: flex; justify-content: center; align-items: center;">
                    <img style="width: 110px;" src="assets/images/htb1.png" alt="">
                    <div style="width: 80%; padding-left: 50px;">
                        <div style="font-size: 15px; color: #FFE5A5; padding-bottom: 20px;">CREATE A WALLET</div>
                        <div style="font-size: 12px; color: #fff;">
                            Download Tronlink or your wallet of choice from the app store or google play store for free.
                            Desktop users, download the Google Chrome extension on Google Store.
                        </div>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
            <div class="row" style="margin-bottom: 75px;">
                <div class="col-md-4"></div>
                <div class="col-md-8" style="display: flex; justify-content: center; align-items: center;">
                    <img style="width: 110px;" src="assets/images/htb2.png" alt="">
                    <div style="width: 80%; padding-left: 50px;">
                        <div style="font-size: 15px; color: #FFE5A5; padding-bottom: 20px;">GET SOME $TRX</div>
                        <div style="font-size: 12px; color: #fff;">
                            Have $TRX in your wallet to buy Lands. If you don’t have any $TRX, you can buy directly on
                            Tronlink, transfer from another wallet, or buy on another exchange and send it to your wallet.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 75px;">
                <div class="col-md-8" style="display: flex; justify-content: center; align-items: center;">
                    <img style="width: 110px;" src="assets/images/htb3.png" alt="">
                    <div style="width: 80%; padding-left: 50px;">
                        <div style="font-size: 15px; color: #FFE5A5; padding-bottom: 20px;">Buy A LAND</div>
                        <div style="font-size: 12px; color: #fff;">
                            Go to <a style="color: #fff;" href="https://memecity.xyz/map">MAP</a>, choose a pixel of Lands
                            you want to buy, if it still available, click connect wallet and buy.
                        </div>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
            <div class="row" style="margin-bottom: 75px;">
                <div class="col-md-4"></div>
                <div class="col-md-8" style="display: flex; justify-content: center; align-items: center;">
                    <img style="width: 110px;" src="assets/images/htb4.png" alt="">
                    <div style="width: 80%; padding-left: 50px;">
                        <div style="font-size: 15px; color: #FFE5A5; padding-bottom: 20px;">BUY MORE FARMER</div>
                        <div style="font-size: 12px; color: #fff;">
                            If you don't want to wait for your workforce to grow, use $TRX or $MCC to buy more Farmers, go
                            to <a style="color: #fff;" href="https://memecity.xyz/farmers">FARMERS</a>, select the number of
                            Farmers you want to buy and finish the payment.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" id="tokenomics">
            <div class="home-section-2 tokenomics">
                <img class="home-section-2-element" style="position: absolute; z-index: 0;"
                    src="assets/images/home-item-bg1.png" alt="">
                <div class="home-section-2-element"
                    style="display: flex ;position: relative;z-index: 1; padding: 75px 50px; justify-content: center; align-items: center; color: #4B2206;">
                    <div style="width: 50%; border-right: 1px solid; text-align: center; padding: 55px 0;">
                        <div style="font-size: 25px;">TOKENOMICS</div>
                        <div style="font-size: 12px;">supply: 1,000,000,000</div>
                    </div>
                    <div style="width: 50%; text-align: center;">
                        <div style="font-size: 25px;">TAX</div>
                        <div style="font-size: 12px;">0% Buy / 0% Sell</div>
                        <div style="font-size: 12px;">100% of the LP is Burnt</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="container">
            <div style="text-align: center; font-size: 32px; color: #FFE5A5;">ROADMAP</div>
            <div class="row">
                <div class="col-md-4" style="text-align: center;">
                    <div>Phase 1</div>
                    <p>Meme</p>
                </div>
                <div class="col-md-4" style="text-align: center;">
                    <div>Phase 2</div>
                    <p>Vibe and HODL</p>
                </div>
                <div class="col-md-4" style="text-align: center;">
                    <div>Phase 3</div>
                    <p>Meme Takeover</p>
                </div>
            </div>
            <div style="width: 100%; border-top: 2px dashed;"></div>
            <div class="row roadmap">
                <div class="col-md-4" style="text-align: center;">
                    <img src="assets/images/roadmap-stick.png" alt="">
                </div>
                <div class="col-md-4" style="text-align: center;">
                    <img src="assets/images/roadmap-stick.png" alt="">
                </div>
                <div class="col-md-4" style="text-align: center;">
                    <img src="assets/images/roadmap-stick.png" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div style="width: 80%; font-size: 12px; color: #fff; line-height: 30px; border: 1px solid #FFE5A5; border-radius: 20px; padding: 15px 0; margin: auto;">
                        <ul>
                            <li>Steallaunch</li>
                            <li>Marketing Push</li>
                            <li>1M Market Cap</li>
                            <li>Burning Liquidity</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div style="width: 80%; font-size: 12px; color: #fff; line-height: 30px; border: 1px solid #FFE5A5; border-radius: 20px; padding: 15px 0; margin: auto;">
                        <ul>
                            <li>Steallaunch</li>
                            <li>Marketing Push</li>
                            <li>1M Market Cap</li>
                            <li>Burning Liquidity</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div style="width: 80%; font-size: 12px; color: #fff; line-height: 30px; border: 1px solid #FFE5A5; border-radius: 20px; padding: 15px 0; margin: auto;">
                        <ul>
                            <li>Steallaunch</li>
                            <li>Marketing Push</li>
                            <li>1M Market Cap</li>
                            <li>Burning Liquidity</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div> -->
    </div>
</template>

<script>
export default {
    name: 'DashboardVue',
    components: {},
    data() {
        return {
            user: {
                address: '0x',
                balance: 0,
                currentTrans: '0x'
            },
            windowWidth: window.innerWidth
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // You can use 'auto' for an instant scroll
            });
        },
    },
    mounted: async function () {
        console.log(this.windowWidth)
    }
}
</script>