<template>
    <div class="loading"
       style="width: 100%;height: 100vh;background: #111;z-index: 10005;position: absolute; display: flex; justify-content: center; align-items: center;"
       :style="initLoading ? {} : { display: 'none' }">
       <div class="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
       </div>
    </div>
    <MainHeader ref="mainHeader"/>
    <div class="page-content">
       <div class="container">
          <div class="row">
            <div class="col-md-4">
               <div class="web-item">
                  <div style="height:110px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                     <div class="web-item-element">Number of Lands</div>
                     <div class="web-item-element">05</div>
                     <!-- <a href="/map" class="web-item-element web-item-button">Buy more</a> -->
                     <a href="#" class="web-item-element web-item-button" style="cursor: not-allowed;">Buy more</a>
                  </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="web-item">
                  <div style="height:110px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                     <div class="web-item-element">Number of Farmers</div>
                     <div class="web-item-element">05</div>
                     <!-- <a href="/farmers" class="web-item-element web-item-button" >Buy more</a> -->
                     <a href="#" class="web-item-element web-item-button" style="cursor: not-allowed;">Buy more</a>
                  </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="web-item">
                  <div style="height:110px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                     <div class="web-item-element">Incoming Farmers</div>
                     <div class="web-item-element">03</div>
                     <!-- <div  class="web-item-element web-item-button">Buy more</div> -->
                  </div>
               </div>
            </div>
          </div><!-- .row -->
          <div class="row" style="padding-top: 50px;">
            <div class="col-md-2"></div>
            <div class="col-md-4">
               <div class="web-item">
                  <div style="height:110px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                     <div class="web-item-element">MCC Mind Rate</div>
                     <div class="web-item-element">06 MCC/h</div>
                     <!-- <div  class="web-item-element web-item-button">Buy more</div> -->
                  </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="web-item">
                  <div style="height:110px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                     <div class="web-item-element">Total MCC</div>
                     <div class="web-item-element">05</div>
                     <div  class="web-item-element web-item-button" style="cursor: not-allowed;">Claim</div>
                  </div>
               </div>
            </div>
            <div class="col-md-2"></div>
          </div>
       </div><!-- .container -->
    </div><!-- .page-content -->
    <Footer />
 </template>
  
 <script>
 import MainHeader from '../components/MainHeader'
 import Footer from "../components/Footer"

 export default {
    name: 'DashboardVue',
    components: { MainHeader, Footer },
    data() {
       return {
          user: {
             address: '0x',
             balance: 0,
             currentTrans: '0x'
          },
       }
    },
    methods: {
       scrollToTop() {
          window.scrollTo({
             top: 0,
             behavior: 'smooth' // You can use 'auto' for an instant scroll
          });
       },
    },
    mounted: async function () {
    }
 }
 </script>