import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import { WagmiPlugin } from '@wagmi/vue'
// import {config} from "./web3config"
// import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import '@tronweb3/tronwallet-adapter-vue-ui/style.css';


// const queryClient = new QueryClient()

const app = createApp(App)
app.use(router)
// app.use(WagmiPlugin, {config})
// app.use(VueQueryPlugin, { queryClient })
app.mount('#app')
