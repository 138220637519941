<template>
  <nav class="navbar navbar-expand-lg navbar-light" style="z-index: 10">
    <a class="navbar-brand navbar-toggler" href="/" style="border: none">
      <img src="images/logo.png" height="50" alt="" />
    </a>
    <button
      ref="navbarToggler"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      style="border-color: #fff !important"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      style="
        justify-content: space-between;
        margin-left: -1rem;
        margin-right: -1rem;
      "
    >
      <ul class="navbar-nav">
        <li class="nav-item" :class="{ active: navTabActive == 'home' }">
          <a class="nav-link" href="/">Home</a>
        </li>
        <li class="nav-item" :class="{ active: navTabActive == 'dashboard' }">
          <a class="nav-link" href="/dashboard"
            >Dashboard <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item" :class="{ active: navTabActive == 'lands' }">
          <a class="nav-link" href="/lands">Lands</a>
        </li>
        <li class="nav-item" :class="{ active: navTabActive == 'farmers' }">
          <a class="nav-link" href="/farmers">Farmers</a>
        </li>
        <li class="nav-item" :class="{ active: navTabActive == 'map' }">
          <a class="nav-link" href="/map">Map</a>
        </li>
      </ul>
      <div style="text-align: center">
        <button
          style="
            padding: 10px;
            background: rgba(255, 229, 165, 0.15);
            box-shadow: 0 0;
            border: 2px solid;
            border-radius: 15px;
            color: #ffe5a5;
            margin-right: 25px;
          "
        >
          <a
            class="navbar-brand"
            style="color: #ffe5a5; margin-right: 0; font-size: 14px"
            @click="connectWallet()"
          >
            {{
              address?.length > 20
                ? address?.substring(0, 6).toUpperCase() +
                  "..." +
                  address
                    ?.substring(address?.length - 4, address?.length)
                    .toUpperCase()
                : "Connect Wallet"
            }}
          </a>
        </button>
      </div>
    </div>
  </nav>

  <div
    class="modal fade"
    id="transactionModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="transactionModalLabel"
    aria-hidden="true"
  >
    <!-- <div class="modal-dialog" role="document" style="max-width: 550px; top: 20%;">
            <div class="modal-content currencySearchWrapperV2" style="height: 350px;">
                <div class="modal-header" style="border-bottom: none; padding-left: 0;">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            style="color: #686c80;">
                            <path
                                d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                fill="currentColor" />
                        </svg>
                    </button>
                </div>
                <div class="modal-body" style="padding: 0; display: flex; flex-direction: column;">
                    <div class="status-icon" style="background-color: rgb(68, 158, 255);"><em style="color: #fff;"
                            class="ti ti-check"></em></div>
                    <div
                        style="display: flex; width: 250px; margin: auto; justify-content: space-around; align-items: center;">
                        <div style="width: 100px;">
                            <img :src="tokenSelected[0].info?.icon" alt="" height="28" width="28">
                            <span>{{ tokenSelected[0].info?.symbol?.toUpperCase() }}</span>
                        </div>
                        <div><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                fill="rgb(68, 158, 255)">
                                <path d="M14 12l-14 9v-18l14 9zm-4-9v4l8.022 5-8.022 5v4l14-9-14-9z" />
                            </svg></div>
                        <div style="width: 100px; text-align: right;">
                            <img :src="tokenSelected[1].info?.icon" alt="" height="28" width="28">
                            <span>{{ tokenSelected[1].info?.symbol?.toUpperCase() }}</span>
                        </div>
                    </div>
                    <h2 style="text-align: center; color: #fff;">Success</h2>
                    <p style="text-align: center;"><a target="_blank"
                            :href="(chainSelected.id == 'ethereum' ? 'https://etherscan.io/tx/' : 'https://bscscan.com/tx/') + user.currentTrans">View
                            on Explorer</a></p>
                </div>
                <div class="modal-footer" style="border-top: none;">
                </div>
            </div>
        </div> -->
  </div>
  <Modal v-model:visible="showConnectModal" :zIndex="5" :title="''">
    <div
      v-if="address?.length > 20"
      class="right-lands-place-size"
      style="
        background-color: rgba(240, 229, 220, 0.9);
        border: 3px solid #f3e8df;
        background-size: contain;
        background-repeat: no-repeat;
        color: #fff;
        margin: auto;
        padding: 15px;
      "
    >
      <div
        style="width: 100%; text-align: right; cursor: pointer"
        v-on:click="
          () => {
            showConnectModal = false;
          }
        "
      >
        X
      </div>
      <div style="padding: 20px 0">
        {{
          address?.substring(0, 6).toUpperCase() +
          "..." +
          address?.substring(address?.length - 4, address?.length).toUpperCase()
        }}
      </div>
      <div>
        <button
          style="padding: 8px; font-size: 12px"
          v-on:click="tronDisconnect"
        >
          Disconnect
        </button>
      </div>
    </div>
    <div
      v-else
      class="right-lands-place-size"
      style="
        background-color: rgba(240, 229, 220, 0.9);
        border: 3px solid #f3e8df;
        background-size: contain;
        background-repeat: no-repeat;
        color: #fff;
        margin: auto;
        padding: 15px;
      "
    >
      <div
        style="width: 100%; text-align: right; cursor: pointer"
        v-on:click="
          () => {
            showConnectModal = false;
          }
        "
      >
        X
      </div>
      <!-- <div
        v-for="connector in connectors"
        :key="connector.name"
        style="padding: 10px"
      >
        <img
          v-if="connector.name == 'MetaMask'"
          src="assets/images/metamask_icon.png"
          alt=""
          width="40"
          style="padding: 2px; margin-right: 10px"
        />
        <img
          v-if="connector.name == 'Coinbase Wallet'"
          src="assets/images/coinbase_wallet_icon.png"
          alt=""
          width="40"
          style="padding: 2px; margin-right: 10px"
        />
        <img
          v-if="connector.name == 'Injected'"
          src="assets/images/other_wallet_icon.png"
          alt=""
          width="40"
          style="padding: 2px; margin-right: 10px"
        />
        <img
          v-if="connector.name == 'WalletConnect'"
          src="assets/images/walletconnect_icon.png"
          alt=""
          width="40"
          style="padding: 2px; margin-right: 10px; border-radius: 10px"
        />

        <button
          @click="
            () => {
              connect({ connector, chainId });
              showConnectModal = !showConnectModal;
            }
          "
          style="padding: 8px; font-size: 12px"
        >
          {{
            connector.name == "Injected"
              ? "Other Wallet"
              : connector.name == "WalletConnect"
              ? "Wallet Connect"
              : connector.name
          }}
        </button>
      </div> -->
      <div v-if="loadingTronConnect" style="width: 100%; height: 100%; display: flex;justify-content: center;align-items: center;">
        <div  class="loader" ></div>
      </div>
      <div v-else>
        <div
          v-for="adapterName in adapterNames"
          :key="adapterName"
          style="padding: 10px"
        >
          <button @click="() => tronConnectWallet(adapterName)">
            {{ adapterName }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import {
  // MCC_ADDRESS, ROOT_ADDRESS,
  WALLET_STORAGE,
} from "../constants";
import { Buffer } from "buffer";
import { Modal } from "usemodal-vue3";
import { useWallet } from "@tronweb3/tronwallet-adapter-vue-hooks";

// const web3 = window.solanaWeb3;
window.Buffer = Buffer;

export default {
  name: "MainHeader",
  components: { Modal },
  props: [],
  data() {
    // const { connectors, connect } = useConnect();
    // const { address_, connector } = useAccount();
    const { wallet, wallets, address, select, connect, disconnect } =
      useWallet();
    console.log(
      wallet,
      wallets,
      wallets._rawValue.map((item) => item.adapter.name),
      typeof wallets
    );
    const adapterNames = wallets._rawValue.map((item) => item.adapter.name);

    return {
      navTabActive: "dashboard",
      showConnectModal: false,
      address: address,
      tronConnect: connect,
      adapterNames: adapterNames,
      selectWalletAdapter: select,
      tronDisconnect: disconnect,
      loadingTronConnect: false,
    };
  },
  methods: {
    sayHello: function () {
      alert("Hello");
    },
    connectWallet: async function () {
      this.$refs.navbarToggler.click();
      this.showConnectModal = true;
    },
    tronConnectWallet: async function (adapterName) {
      this.loadingTronConnect = true;
      await this.selectWalletAdapter(adapterName);
      try {
        await this.tronConnect();
      } catch (error) {
        console.log(error);
      }
      this.loadingTronConnect = false;
    },
    updateTokenBalance: async function () {},
    buyLands: async function () {},
  },
  mounted() {
    // console.log("310 mainheader ", wallet);

    console.log(this.$router.currentRoute.value.name);
    this.navTabActive = this.$router.currentRoute.value.name;
    //load localstorage
    var saveStatus = localStorage.getItem(WALLET_STORAGE.SAVE_STATUS);
    if (saveStatus == 1) {
      // this.connectWallet();
    }
  },
};
</script>
<style>
.mobileMenuActive {
  color: #449eff !important;
}
.modal-vue3-content {
  background: none !important;
  border: none !important;
  max-width: 500px !important;
  width: 100% !important;
}
.modal-vue3-footer {
  display: none !important;
}
.modal-vue3-header {
  display: none !important;
}
</style>
