<template>
    <div class="loading"
        style="width: 100%;height: 100vh;background: #111;z-index: 10005;position: absolute; display: flex; justify-content: center; align-items: center;"
        :style="initLoading ? {} : { display: 'none' }">
        <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <MainHeader />
    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-8" style="margin: auto;">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="web-item2">
                                <div style="padding:20px;text-align:center;height: 100%;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div>Lands</div>
                                    <div>
                                        <img width="100" src="assets/images/lands1.png" alt="">
                                    </div>
                                    <div>#111</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="web-item2">
                                <div style="padding:20px;text-align:center;height: 100%;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div>Water</div>
                                    <div>
                                        <img width="100" src="assets/images/lands2.png" alt="">
                                    </div>
                                    <div>#111</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="web-item2">
                                <div style="padding:20px;text-align:center;height: 100%;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div>Tree</div>
                                    <div>
                                        <img width="100" src="assets/images/lands3.png" alt="">
                                    </div>
                                    <div>#111</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 right-lands-place">
                    <div class="right-lands-place-size" style="background-image:url('assets/images/item-bg3.png');background-color:rgba(82, 66, 51, 0.3);background-size: contain;background-repeat: no-repeat; color: #fff; margin: auto;">
                        <div class="right-lands-place-container">
                            <div style="text-align: center; font-family: 'Press Start 2P';">INCOMING FARMERS</div>
                            <div style="display: flex;width: 100%;">
                                <div
                                    style="width:30%;background-image:url('assets/images/subitem-bg1.png');background-size: contain;background-position: center;margin: 15px;background-repeat: no-repeat;padding: 20px 0; font-family: 'Press Start 2P'; text-align: center;">
                                    <p style="font-size: 12px; padding-bottom: 0px; margin-bottom: 5px; color: #4B2206;">
                                        Farmer</p>
                                    <img src="assets/images/farmer1.png" width="30" alt="">
                                </div>
                                <div style="width: 10%; display: flex; justify-content: center; align-items: center;">
                                    x3
                                </div>
                                <div
                                    style="width:60%; padding-left: 30px;display: flex;flex-direction: column;justify-content: center;/* align-items: center; */">
                                    <div class="progress-bar">
                                        <div class="progress-bar-processing" style="width: 20%;"></div>
                                    </div>
                                    <div style="font-family: 'Press Start 2P'; text-align: center">--:--:--</div>
                                </div>
                            </div>
                            <div style="display: flex;width: 100%;">
                                <div
                                    style="width:30%;background-image:url('assets/images/subitem-bg1.png');background-size: contain;background-position: center;margin: 15px;background-repeat: no-repeat;padding: 20px 0; font-family: 'Press Start 2P'; text-align: center;">
                                    <p style="font-size: 12px; padding-bottom: 0px; margin-bottom: 5px; color: #4B2206;">
                                        Farmer</p>
                                    <img src="assets/images/farmer1.png" width="30" alt="">
                                </div>
                                <div style="width: 10%; display: flex; justify-content: center; align-items: center;">
                                    x2
                                </div>
                                <div
                                    style="width:60%; padding-left: 30px;display: flex;flex-direction: column;justify-content: center;/* align-items: center; */">
                                    <div class="progress-bar">
                                        <div class="progress-bar-processing" style="width: 20%;"></div>
                                    </div>
                                    <div style="font-family: 'Press Start 2P'; text-align: center">--:--:--</div>
                                </div>
                            </div>
                            <div style="display: flex;width: 100%;">
                                <div
                                    style="width:30%;background-image:url('assets/images/subitem-bg1.png');background-size: contain;background-position: center;margin: 15px;background-repeat: no-repeat;padding: 20px 0; font-family: 'Press Start 2P'; text-align: center;">
                                    <p style="font-size: 12px; padding-bottom: 0px; margin-bottom: 5px; color: #4B2206;">
                                        Farmer</p>
                                    <img src="assets/images/farmer1.png" width="30" alt="">
                                </div>
                                <div style="width: 10%; display: flex; justify-content: center; align-items: center;">
                                    x1
                                </div>
                                <div
                                    style="width:60%; padding-left: 30px;display: flex;flex-direction: column;justify-content: center;/* align-items: center; */">
                                    <div class="progress-bar">
                                        <div class="progress-bar-processing" style="width: 20%;"></div>
                                    </div>
                                    <div style="font-family: 'Press Start 2P'; text-align: center">--:--:--</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <Footer />
</template>

<script>
import MainHeader from '../components/MainHeader'
import Footer from "../components/Footer"

export default {
    name: 'LandsVue',
    components: { MainHeader, Footer },
    data() {
        return {
            user: {
                address: '0x',
                balance: 0,
                currentTrans: '0x'
            },
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // You can use 'auto' for an instant scroll
            });
        },
    },
    mounted: async function () {
    }
}
</script>