<template>
    <MainHeader ref="mainHeader" style="display: none;" />
    <!-- <div @click="showSelectedList()">Show LargeLand select</div> -->
    <!-- <div style="position: relative; overflow: scroll;" :style="this.windowSize.h > this.windowSize.w ? { width: '100vw', height: '100vh'}: {width: '100vw', height: '53.7vw'}">
        
    </div> -->
    <img ref="mapType" style="width: 160px; height: 112px; display: none;" src="assets/images/map_type.png" alt="">

    <div class="container2" style=" margin-top: 0px;">
        <div ref="frameMap"
            style="position: relative;border: 20px solid;border-image: url(assets/images/frame_map.png) 30 stretch; overflow: scroll;">
            <div style="width: 100%; position: relative;">
                <div ref="imageMap" @mousedown="startDrag" @mouseup="stopDrag"
                    style="position: relative;width: 6400px; height: 4480px; background: #111;">
                    <!-- <img style="height: 100%; position: absolute; left: -15px" src="assets/images/map_3.webp" alt=""> -->
                    <div v-for="(_, rowId) in row" class="row" v-bind:key="'rowId' + rowId">
                        <!-- <div style="position: relative; cursor: pointer;" v-for="(_, colId) in col" class="cellNotBorder"
                                    :class="{ selected: checkSelected(rowId, colId), cell: checkInLargeLand(colId, rowId) }" @mousemove="drag(rowId, colId)"
                                    v-on:click="showSelected(rowId, colId)" v-bind:key="'colId' + rowId + ',' + colId"> -->
                        <div style="position: relative; cursor: pointer;" v-for="(_, colId) in col"
                            :class="'cell' + checkInLargeLand(colId, rowId)" @mousemove="drag(rowId, colId)"
                            v-on:click="showSelected(rowId, colId)" v-bind:key="'colId' + rowId + ',' + colId">
                            <div class="coordinates"
                                style="width: 100%; height: 100%; position: absolute; width: 80px; font-size: 7px; color: #fff;">
                                ({{ colId }}, {{ rowId }})
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div ref="imageMap" @click="showSelected" @mousemove="mouseMoveHandle" style="position: relative;" :style="{width: mapWidth * scaleRate + 'px'}">
                        <img style="width: 100%;" src="assets/images/map_3.webp" alt="">
                        <div style="position: absolute; font-size: 10px; color: #fff;" :style="overMouseStatus ? {display: 'block', left: (mapHoverCoordinates.x/cellMapWidth > 150 ? (mapHoverCoordinates.x - 100) : mapHoverCoordinates.x) + 'px', top:( mapHoverCoordinates.y/cellMapWidth > 100 ?  (mapHoverCoordinates.y - 30) : mapHoverCoordinates.y) + 'px'} : {display: 'none'}">({{ Math.floor(mapHoverCoordinates.x/cellMapWidth) }}, {{ Math.floor(mapHoverCoordinates.y/cellMapWidth) }})</div>
                    </div> -->
            </div>
            <!-- <div class="resize-map-button">
                <button v-on:click="changeScale(-0.1)"
                    style="background: none; border: none; padding: 0; padding-right: 5px;">
                    <img src="assets/images/downsize.png" alt="" width="45">
                </button>
                <button v-on:click="changeScale(0.1)" style="background: none; border: none; padding: 0;">
                    <img src="assets/images/upsize.png" alt="" width="45">
                </button>
            </div> -->
        </div>
    </div>
    <!-- <div style="position: fixed; top: 10px; left: 10px;">{{ startSelectLargeLands }}</div> -->

    <div class="modal fade" id="transModal" tabindex="-1" role="dialog" aria-labelledby="transModalLabel"
        aria-hidden="true">
        <div v-if="checkLandOwner() != '0x'" class="modal-dialog" role="document"
            :style="windowSize.w < 550 ? { transform: 'scale(' + (windowSize.w - 20) / 550 + ')' } : {}"
            style='width: 550px;top: 20%;background-image: url("assets/images/map_land_details_with_owner.png");background-size: contain;background-repeat: no-repeat;background-position: center; color: #fff;'>
            <div class="modal-content currencySearchWrapperV2" style="height: 350px; background: none; border: none;">
                <div class="modal-header" style="border-bottom: none; padding-left: 0;">
                    <!-- <h6 class="modal-title" id="exampleModalLabel" style="color: #d9d7c7; font-weight: 600; font-size: 18px;">
                  Settings</h6> -->
                    <button type="button" class="close button-close" data-dismiss="modal" aria-label="Close"
                        style="padding: 0; margin-top: 0.1rem; margin-right: 0.1rem;">
                        <img src="assets/images/close_lands_details.png" alt="">
                    </button>
                </div>
                <div class="modal-body" style="padding: 0 50px; font-size: 12px;">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <div
                            style="width: 40%; position: relative; display: flex; justify-content: center; align-items: center;">
                            <img src="assets/images/land_frame.png" style="position: absolute; width: 100%;" alt="">
                            <img src="assets/images/lands3.png" style="position: relative; width: 65%;" alt="">
                        </div>
                        <div style="width: 60%; padding-left: 30px;">
                            <div style="color: #FFE5A5; font-size: 20px;">Lands</div>
                            <div style="font-size: 10px; padding-top: 4px;">#0003</div>
                            <div style="font-size: 10px; padding-top: 10px;">PRICE</div>
                            <div style="font-size: 10px;"> <span style="color: #FFE5A5; font-size: 18px;">0.5</span> TRX
                            </div>
                            <div style="font-size: 10px; padding-top: 12px;">Coordinates</div>
                            <div style="color: #FFE5A5;">({{ locationSelected.x }}, {{ locationSelected.y }})</div>

                        </div>
                    </div>
                    <div style="padding-top: 50px;">
                        <div style="padding-top: 20px;">This Land acquired by</div>
                        <div style="color: #FFE5A5;">0x000...000</div>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: none;">
                </div>
            </div>
        </div>
        <div v-else class="modal-dialog" role="document"
            :style="windowSize.w < 550 ? { transform: 'scale(' + (windowSize.w - 20) / 550 + ')' } : {}"
            style='width: 550px;top: 20%;background-image: url("assets/images/map_land_details.png");background-size: contain;background-repeat: no-repeat;background-position: center; color: #fff;'>
            <div class="modal-content currencySearchWrapperV2" style="height: 643px; background: none; border: none;">
                <div class="modal-header" style="border-bottom: none; padding-left: 0;">
                    <!-- <h6 class="modal-title" id="exampleModalLabel" style="color: #d9d7c7; font-weight: 600; font-size: 18px;">
                  Settings</h6> -->
                    <button type="button" class="close button-close-2" data-dismiss="modal" aria-label="Close"
                        style="padding: 0; margin-top: 1rem; margin-right: 0.1rem;">
                        <img src="assets/images/close_lands_details.png" alt="">
                    </button>
                </div>
                <div class="modal-body" style="padding: 0 50px; font-size: 12px;">
                    <div style="display: flex; flex-direction: row; align-items: center; padding-top: 35px;">
                        <div
                            style="width: 40%; position: relative; display: flex; justify-content: center; align-items: center;">
                            <img src="assets/images/land_frame.png" style="position: absolute; width: 100%;" alt="">
                            <img src="assets/images/lands3.png" style="position: relative; width: 65%;" alt="">
                        </div>
                        <div style="width: 60%; padding-left: 30px;">
                            <div style="color: #FFE5A5; font-size: 20px;">Lands</div>
                            <div style="font-size: 10px; padding-top: 4px;">#0003</div>
                            <div style="font-size: 10px; padding-top: 10px;">PRICE</div>
                            <div style="font-size: 10px;"> <span style="color: #FFE5A5; font-size: 18px;">0.5</span> TRX
                            </div>
                        </div>
                    </div>
                    <div style="padding-top: 50px;">
                        <div style="padding-top: 30px;">Dimensions : <span style="color: #FFE5A5;">2x2</span></div>
                        <div style="padding-top: 20px;">Coordinates : <span style="color: #FFE5A5;">({{ locationSelected.x
                        }}, {{ locationSelected.y }})</span></div>
                        <div style="padding-top: 20px;">Type : <span style="color: #FFE5A5;">Land</span></div>
                        <div style="padding-top: 30px;">
                            Receive 1 Farmers at fisrt, and 1 every two days until reached 5 Farmers in total.
                        </div>

                    </div>
                    <div style="text-align: center; padding-top: 50px;">
                        <button
                            style="width: 100%; padding: 10px; background: rgba(255, 229, 165, 0.15); box-shadow: 0 0; border: 2px solid; border-radius: 15px; color: #FFE5A5; margin-right: 25px;">
                            <a class="navbar-brand" style="color: #FFE5A5; margin-right: 0; font-size: 14px;"
                                @click="$refs.mainHeader.connectWallet()">
                                <!-- {{ $refs.mainHeader?.user?.address == '0x' ? 'Connect Wallet' : $refs.mainHeader?.user?.address?.substring(0, 6).toUpperCase() + "..." + $refs.mainHeader?.user?.address?.substring($refs.mainHeader?.user?.address?.length - 4, $refs.mainHeader?.user?.address?.length).toUpperCase() }} -->
                                BUY
                            </a>
                        </button>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: none;">
                </div>
            </div>
        </div>
    </div>

    <Footer />
</template>
<script>
import MainHeader from '../components/MainHeader'
import Footer from "../components/Footer"
import { LARGE_LANDS } from "../constants"
import html2canvas from "html2canvas";

const MAP_ROOT_SIZE = {
    w: 6400,
    h: 4480
};
const CELL_WIDTH = 40;


const LANDS_COLOR_ROOT = {
    FOREST: {
        r: 24, g: 161, b: 73
    },
    LAND: {
        r: 112, g: 68, b: 73
    },
    WATER: {
        r: 2, g: 99, b: 144
    }
};
const landTypeClassification = (color) => {
    var deltaForest = Math.sqrt(Math.pow(color.r - LANDS_COLOR_ROOT.FOREST.r, 2) + Math.pow(color.g - LANDS_COLOR_ROOT.FOREST.g, 2) + Math.pow(color.b - LANDS_COLOR_ROOT.FOREST.b, 2));
    var deltaLand = Math.sqrt(Math.pow(color.r - LANDS_COLOR_ROOT.LAND.r, 2) + Math.pow(color.g - LANDS_COLOR_ROOT.LAND.g, 2) + Math.pow(color.b - LANDS_COLOR_ROOT.LAND.b, 2));
    var deltaWater = Math.sqrt(Math.pow(color.r - LANDS_COLOR_ROOT.WATER.r, 2) + Math.pow(color.g - LANDS_COLOR_ROOT.WATER.g, 2) + Math.pow(color.b - LANDS_COLOR_ROOT.WATER.b, 2));
    if (deltaForest < deltaLand && deltaForest < deltaWater) {
        return "FOREST";
    } else if (deltaLand < deltaForest && deltaLand < deltaWater) {
        return "LAND";
    } else {
        return "WATER";
    }
}


export default {
    name: 'Map2Vue',
    components: { MainHeader, Footer },
    data() {
        return {
            col: new Array(160).fill(1),
            row: new Array(112).fill(1),
            scaleRate: 1.2,
            minScaleRate: 0.3,
            locationSelected: { x: -1, y: -1 },
            windowSize: { w: 100, h: 100 },
            mapHoverCoordinates: { x: 0, y: 0 },
            frameMapWidth: 0,
            mapWidth: 0,
            cellMapWidth: 0,
            waitMouse: null,
            overMouseStatus: false,
            largeLands: [],
            startSelectLargeLands: { x: 0, y: 0 },
            inDrag: false,
            // canvas: document.createElement('canvas'),
            mapType: null
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // You can use 'auto' for an instant scroll
            });
        },
        showSelected: function (rowId, colId) {
            console.log(rowId, colId)
            this.getPixelColor(colId, rowId);
            if (this.checkSelected(rowId, colId)) {
                this.largeLands = this.largeLands.filter(obj => !(obj.x == rowId && obj.y == colId));
            } else {
                this.largeLands.push({ x: rowId, y: colId });
            }
            // var jQuery = window.jQuery;
            // jQuery('#transModal').modal('show');
        },
        checkSelected: function (rowId, colId) {
            if (this.largeLands.some(obj => obj.x == rowId && obj.y == colId)) {
                return true;
            }
            return false;
        },
        changeScale: function (tempScale) {
            this.scaleRate += tempScale;
            if (this.scaleRate >= 3) {
                this.scaleRate = 3;
            }
            if (this.scaleRate <= this.minScaleRate) {
                this.scaleRate = this.minScaleRate;
            }
            this.cellMapWidth = CELL_WIDTH * (this.mapWidth * this.scaleRate) / MAP_ROOT_SIZE.w;
            console.log("cellSize : ", this.cellMapWidth);
        },
        checkInLargeLand: (colId, rowId) => {
            var tempLand = LARGE_LANDS.filter(item => (
                item[0].x <= colId && colId <= item[1].x &&
                item[0].y <= rowId && rowId <= item[1].y
            ));
            if (tempLand.length > 0) {
                tempLand = tempLand[0];
                if (
                    tempLand[0].x < colId && colId <= tempLand[1].x &&
                    tempLand[0].y <= rowId && rowId < tempLand[1].y
                ) {
                    return 1;
                } else if (tempLand[0].x == colId && tempLand[1].y == rowId) {
                    return 0;
                } else if (tempLand[0].x == colId) {
                    return 2;
                } else if (tempLand[1].y == rowId) {
                    return 3;
                }
                return 1;
            }
            return 0;
        },
        checkLandOwner: function () {
            // console.log(rowId, colId)
            return '0x';
        },
        mouseMoveHandle: function (event) {
            this.overMouseStatus = false;
            const imageMap = this.$refs.imageMap;
            var boundingRect = imageMap.getBoundingClientRect();
            var mouseX0 = event.clientX - boundingRect.left;
            var mouseY0 = event.clientY - boundingRect.top;
            this.mapHoverCoordinates = { x: mouseX0, y: mouseY0 };
            setTimeout(() => {
                if (this.mapHoverCoordinates.x == mouseX0 && this.mapHoverCoordinates.y == mouseY0) {
                    if (this.mapHoverCoordinates.x < 0) {
                        this.mapHoverCoordinates.x = 0;
                    }
                    if (this.mapHoverCoordinates.y < 0) {
                        this.mapHoverCoordinates.y = 0;
                    }
                    this.overMouseStatus = true;
                }
            }, 320);
        },
        startDrag: function () {
            this.inDrag = true;
        },
        drag: function (rowId, colId) {
            if (this.inDrag == false) {
                return;
            }
            // const imageMap = this.$refs.imageMap;
            // var boundingRect = imageMap.getBoundingClientRect();
            // var mouseX0 = event.clientX - boundingRect.left;
            // var mouseY0 = event.clientY - boundingRect.top;
            // mouseX0 = mouseX0 > 0 ? mouseX0 : 0;
            // mouseY0 = mouseY0 > 0 ? mouseY0 : 0;
            // var tempPosition = {
            //     x: Math.floor(mouseX0/this.cellMapWidth),
            //     y: Math.floor(mouseY0/this.cellMapWidth)
            // }
            // if(!this.checkSelected(tempPosition.x, tempPosition.y)){
            //     this.largeLands.push({x: tempPosition.x, y: tempPosition.y});
            // }
            if (this.checkSelected(rowId, colId)) {
                // this.largeLands = this.largeLands.filter(obj => !(obj.x == rowId && obj.y == colId));
            } else {
                this.largeLands.push({ x: rowId, y: colId });
            }
        },
        showSelectedList: function () {
            console.log(this.largeLands);
        },
        stopDrag: function () {
            this.inDrag = false;
        },
        getPixelColor(x, y) {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = this.mapType.width;
            canvas.height = this.mapType.height;
            console.log(this.mapType.width, this.mapType.height)

            context.drawImage(this.mapType, 0, 0, this.mapType.width, this.mapType.height);
            const pixelData = context.getImageData(x, y, 1, 1).data;

            const color = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`;

            console.log(`Color at (${x}, ${y}): ${color}`, landTypeClassification({ r: pixelData[0], g: pixelData[1], b: pixelData[2] }), "In large Land ", this.checkInLargeLand(x, y));
        },
        async capture() {
            const captureElement = this.$refs.imageMap;

            // Sử dụng html2canvas để chụp nội dung của thành phần
            const canvas = await html2canvas(captureElement);

            // Log chiều rộng và chiều cao của canvas để đảm bảo nó không phải là 0
            console.log("Canvas width:", canvas.width);
            console.log("Canvas height:", canvas.height);

            // Chuyển đổi hình ảnh từ canvas thành PNG sử dụng dom-to-image
            //   const dataUrl = await domToImage.toPng(canvas);
            const dataUrl = canvas.toDataURL("image/png");

            // Log địa chỉ URL của hình ảnh để kiểm tra
            console.log("Image URL:", dataUrl);

            // Tạo một liên kết để tải xuống
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = "captured-image.png";

            // Kích hoạt sự kiện click để tải xuống
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
    mounted: async function () {
        console.log(window.innerWidth, window.innerHeight);
        this.windowSize.w = window.innerWidth;
        this.windowSize.h = window.innerHeight
        const frameMap = this.$refs.frameMap;
        console.log(frameMap, frameMap.offsetWidth)
        if (frameMap) {
            this.frameMapWidth = frameMap.offsetWidth;
            this.mapWidth = frameMap.offsetWidth;
            console.log(this.mapWidth);
            // this.minScaleRate = this.frameMapWidth / 1600;
            this.minScaleRate = 1;
            this.cellMapWidth = CELL_WIDTH * (this.mapWidth * this.scaleRate) / MAP_ROOT_SIZE.w;
        }

        //
        this.mapType = this.$refs.mapType;
        this.capture()
    }
}
</script>
<style>
.row {
    display: flex;
}

.cell {
    width: 40px;
    height: 40px;
    /* border-left: 2px dashed rgba(143, 135, 128, 0.9);
    border-bottom: 2px dashed rgba(143, 135, 128, 0.9); */
    border: 2px dashed #111;
    position: relative;
}

.cell0 {
    width: 40px;
    height: 40px;
    border-left: 2px dashed rgba(143, 135, 128, 0.9);
    border-bottom: 2px dashed rgba(143, 135, 128, 0.9);
    position: relative;
}

.cell1 {
    width: 40px;
    height: 40px;
    position: relative;
}

.cell2 {
    width: 40px;
    height: 40px;
    border-left: 2px dashed rgba(143, 135, 128, 0.9);
    position: relative;
}

.cell3 {
    width: 40px;
    height: 40px;
    border-bottom: 2px dashed rgba(143, 135, 128, 0.9);
    position: relative;
}

.coordinates {
    display: none;
}

.coordinates-container:hover .coordinates {
    display: block;
}

.selected {
    background-color: yellow;
}
</style>