<template>
    <div class="loading"
        style="width: 100%;height: 100vh;background: #111;z-index: 10005;position: absolute; display: flex; justify-content: center; align-items: center;"
        :style="initLoading ? {} : { display: 'none' }">
        <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <MainHeader />
    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-8" style="margin: auto;">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="web-item2">
                                <div class="web-item2-sub" style="padding:20px;text-align:center;height: 100%;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div>Farmer</div>
                                    <div>
                                        <img width="100" src="assets/images/farmer1.png" alt="">
                                    </div>
                                    <div>#111</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="web-item2">
                                <div class="web-item2-sub" style="padding:20px;text-align:center;height: 100%;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div>Fisherman</div>
                                    <div>
                                        <img width="100" src="assets/images/farmer2.png" alt="">
                                    </div>
                                    <div>#111</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="web-item2">
                                <div class="web-item2-sub" style="padding:20px;text-align:center;height: 100%;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div>Forester</div>
                                    <div>
                                        <img width="100" src="assets/images/farmer3.png" alt="">
                                    </div>
                                    <div>#111</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 right-lands-place">
                    <div class="right-lands-place-size" style="background-image:url('assets/images/item-bg3.png');background-color:rgba(82, 66, 51, 0.3);background-size: contain;background-repeat: no-repeat; color: #fff; margin: auto;">
                        <div class="right-lands-place-container">
                            <div style="text-align: center; font-family: 'Press Start 2P'; color: #FFE5A5; text-align: left;">BUY MORE</div>
                            <div style="font-size: 10px;">Available to buy</div>
                            <div style="display: flex;width: 100%;">
                                <div
                                    class="farmer-item">
                                    <!-- <p style="font-size: 12px; padding-bottom: 0px; margin-bottom: 5px; color: #4B2206;">
                                        Farmer</p> -->
                                    <img style="margin-bottom: 5px;" src="assets/images/farmer1.png" width="30" alt="">
                                    <p style="color: #4B2206; font-size: 8px; margin-bottom: 0;">250 TRX</p>
                                </div>
                                <div style="width: 30%; display: flex; justify-content: space-between; align-items: center;">
                                    <div style="color: #FFE5A5; border: 1px solid; border-radius: 5px; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; line-height: 30px; font-size: 12px;  cursor: not-allowed ">-</div>
                                    <div>1</div>
                                    <div style="color: #2D5C5B; background-color: #FFE5A5; border: 1px solid; border-radius: 5px; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; line-height: 30px; font-size: 12px; cursor: not-allowed">+</div>
                                </div>
                                <div style="width:45%; padding-left: 15px; font-size: 11px;display: flex;flex-direction: column;justify-content: center;/* align-items: center; */">
                                    = 250 TRX
                                </div>
                            </div>
                            <div style="display: flex;width: 100%;">
                                <div
                                    class="farmer-item">
                                    <!-- <p style="font-size: 12px; padding-bottom: 0px; margin-bottom: 5px; color: #4B2206;">
                                        Farmer</p> -->
                                    <img style="margin-bottom: 5px" src="assets/images/farmer1.png" width="30" alt="">
                                    <p style="color: #4B2206; font-size: 8px; margin-bottom: 0;">250 TRX</p>

                                </div>
                                <div style="width: 30%; display: flex; justify-content: space-between; align-items: center;">
                                    <div style="color: #FFE5A5; border: 1px solid; border-radius: 5px; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; line-height: 30px; font-size: 12px;  cursor: not-allowed ">-</div>
                                    <div>1</div>
                                    <div style="color: #2D5C5B; background-color: #FFE5A5; border: 1px solid; border-radius: 5px; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; line-height: 30px; font-size: 12px; cursor: not-allowed">+</div>
                                </div>
                                <div style="width:45%; padding-left: 15px; font-size: 11px;display: flex;flex-direction: column;justify-content: center;/* align-items: center; */">
                                    = 250 TRX
                                </div>
                            </div>
                            <div style="display: flex;width: 100%;">
                                <div
                                    class="farmer-item">
                                    <!-- <p style="font-size: 12px; padding-bottom: 0px; margin-bottom: 5px; color: #4B2206;">
                                        Farmer</p> -->
                                    <img style="margin-bottom: 5px" src="assets/images/farmer1.png" width="30" alt="">
                                    <p style="color: #4B2206; font-size: 8px; margin-bottom: 0;">250 TRX</p>
                                </div>
                                <div style="width: 30%; display: flex; justify-content: space-between; align-items: center;">
                                    <div style="color: #FFE5A5; border: 1px solid; border-radius: 5px; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; line-height: 30px; font-size: 12px;  cursor: not-allowed ">-</div>
                                    <div>1</div>
                                    <div style="color: #2D5C5B; background-color: #FFE5A5; border: 1px solid; border-radius: 5px; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; line-height: 30px; font-size: 12px; cursor: not-allowed">+</div>
                                </div>
                                <div style="width:45%; padding-left: 15px; font-size: 11px;display: flex;flex-direction: column;justify-content: center;/* align-items: center; */">
                                    = 250 TRX
                                </div>
                            </div>
                            <div class="farmer-buy-area" style="display: flex; justify-content: space-between;">
                                <div style="border: 0.5px solid; border-radius: 8px; padding: 8px; width: 45%; text-align: center; cursor: not-allowed">
                                    Buy with TRX
                                </div>
                                <div style="border: 0.5px solid; border-radius: 8px; padding: 8px; width: 45%; text-align: center; cursor: not-allowed">
                                    Buy with MCC
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <Footer />
</template>

<script>
import MainHeader from '../components/MainHeader'
import Footer from "../components/Footer"

export default {
    name: 'FarmersVue',
    components: { MainHeader, Footer },
    data() {
        return {
            user: {
                address: '0x',
                balance: 0,
                currentTrans: '0x'
            },
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // You can use 'auto' for an instant scroll
            });
        },
    },
    mounted: async function () {
    }
}
</script>