<template>
  <WalletProvider @error="onError" :adapters="adapters" :auto-connect="false">
      <router-view />
  </WalletProvider>
</template>
<script>
import { WalletProvider } from "@tronweb3/tronwallet-adapter-vue-hooks";
import {
  WalletDisconnectedError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";
import {
  BitKeepAdapter,
  LedgerAdapter,
  OkxWalletAdapter,
  TokenPocketAdapter,
  TronLinkAdapter,
  WalletConnectAdapter,
} from "@tronweb3/tronwallet-adapters";

export default {
  name: "App",
  components: { WalletProvider },
  data() {
    const tronLink = new TronLinkAdapter();
    const walletConnect = new WalletConnectAdapter({
      network: "Nile",
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        // example WC app project ID
        projectId: "26d3302ad46f577521e42e939af90ecb",
        metadata: {
          name: "Memecity",
          description: "JustLend WalletConnect",
          url: "https://memecity.xyz/",
          icons: ["https://memecity.xyz/assets/logo.png"],
        },
      },
      web3ModalConfig: {
        themeMode: "dark",
        themeVariables: {
          "--w3m-z-index": "1000",
        },
        // explorerRecommendedWalletIds: 'NONE',
        enableExplorer: true,
        explorerRecommendedWalletIds: [
          "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
          "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
          "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
        ],
        // mobileWallets: [],
        // desktopWallets: []
        // explorerExcludedWalletIds: [
        //   '2c81da3add65899baeac53758a07e652eea46dbb5195b8074772c62a77bbf568'
        // ]
      },
    });
    const ledger = new LedgerAdapter({
      accountNumber: 2,
    });
    const tokenPocket = new TokenPocketAdapter();
    const bitKeep = new BitKeepAdapter();
    const okxWalletAdapter = new OkxWalletAdapter();
    return {
      adapters: [
        tronLink,
        walletConnect,
        ledger,
        tokenPocket,
        bitKeep,
        okxWalletAdapter,
      ],
    };
  },
  methods: {
    onError(e) {
      if (e instanceof WalletNotFoundError) {
        console.error(e.message);
      } else if (e instanceof WalletDisconnectedError) {
        console.error(e.message);
      } else console.error(e.message);
    },
  },
};
</script>
<style>
body {
  font-family: "Press Start 2P";
}

.navbar {
  height: 100px;
}

.nav-item {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.nav-item a {
  font-family: "Press Start 2P";
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #ffe5a5;
}

.web-item {
  background-image: url("assets/images/item-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 35px 0;
  /* height: 250px; */
  max-width: 350px;
  text-align: center;
  margin: auto;
  font-family: "Press Start 2P";
  color: #4b2206;
}

.web-item2 {
  background-image: url("assets/images/item-bg2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 214.75px;
  height: 319.5px;
  margin: auto;
}

.web-item-element {
  padding-bottom: 15px;
  font-size: 15px;
}

.web-item-button {
  border: 1px solid;
  border-radius: 10px;
  width: fit-content;
  /* margin: auto; */
  padding: 5px 20px;
  cursor: pointer;
  color: #4b2206;
}

.web-item-button:hover {
  color: #4b2206;
  text-decoration: none;
}

.page-content {
  margin-top: 150px;
  margin-bottom: 150px;
}

.farmer-buy-area {
  padding-top: 25px;
  font-size: 10px;
}

.home-section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.home-section-1-left-1 {
  color: #fff;
  font-size: 30px;
}

.home-section-1-left-2 {
  font-size: 75px;
  color: #ffe5a5;
}

.home-section-1-right {
  width: 100%;
}

.home-section-2 {
  position: relative;
  margin-top: 300px;
}

.home-section-2-element {
  width: 80%;
  height: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.resize-map-button {
  position: fixed;
  z-index: 10;
  bottom: 15px;
  right: 20px;
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .home-section-1-left-1 {
    color: #fff;
    font-size: 30px;
  }

  .home-section-1-left-2 {
    font-size: 65px;
    color: #ffe5a5;
  }

  .home-section-1-right {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  /* Your styles for screens between 768px and 992px */
  .web-item-element {
    font-size: 12px;
    padding-bottom: 5px;
  }

  .home-section-1-left-1 {
    color: #fff;
    font-size: 30px;
  }

  .home-section-1-left-2 {
    font-size: 65px;
    color: #ffe5a5;
  }

  .home-section-1-right {
    width: 80%;
    margin-top: 100px;
  }

  .web-item div {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 992px) {
  .right-lands-place {
    margin-top: 100px;
  }

  .home-section-1 {
    align-items: center;
  }

  .home-section-2-element {
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .roadmap {
    display: none !important;
  }

  .navbar-expand-lg {
    background-color: rgba(45, 92, 91, 0.65);
  }
  .navbar-collapse {
    background-color: rgba(45, 92, 91, 0.65);
    z-index: 10;
  }
}

@media screen and (max-width: 768px) {
  .home-section-1-left-1 {
    color: #fff;
    font-size: 18px;
  }

  .home-section-1-left-2 {
    font-size: 45px;
    color: #ffe5a5;
  }

  .home-section-2-element {
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .tokenomics {
    transform: scale(0.6);
  }

  .tokenomics .home-section-2-element {
    /* width: 125%; */
    width: 690px;
  }
}

.right-lands-place-container {
  padding: 20px;
}

.right-lands-place-size {
  width: 400px;
  height: 446px;
}

.home-section-3-text-element {
  padding-bottom: 30px;
}

.home-section-3-text-element ul {
  padding-left: 15px;
}

.home-section-3-text-element ul li {
  font-size: 12px;
  color: #fff;
}

@media only screen and (max-width: 1152px) and (min-width: 992px) {
  .right-lands-place-size {
    width: 319.99px;
    height: 356.789px;
  }

  .web-item2 {
    width: 171.8px;
    height: 255.6px;
  }

  .web-item2-sub {
    transform: scale(0.85);
  }

  .right-lands-place-container {
    padding: 0px;
    transform: scale(0.8);
  }

  .farmer-buy-area {
    padding-top: 15px;
    font-size: 8px !important;
  }
}
@media screen and (max-width: 550px) {
  .modal-dialog {
    transform-origin: 0% 0% 0px;
  }
  .button-close {
    margin-right: 1rem !important;
  }
  .button-close-2 {
    margin-top: 0.1rem !important;
  }
}

@media screen and (max-width: 400px) {
  .right-lands-place-size {
    width: 320px;
    height: 356.8px;
  }

  .right-lands-place-container {
    padding: 0px;
    transform: scale(0.85);
  }

  .farmer-buy-area {
    padding-top: 15px;
    font-size: 8px !important;
  }

  .tokenomics {
    transform: scale(0.45);
  }

  .tokenomics .home-section-2-element {
    /* width: 125%; */
    width: 690px;
  }
}

.progress-bar {
  background-color: rgba(255, 255, 255, 1);
  height: 10px;
  margin-bottom: 15px;
}

.progress-bar-processing {
  background-color: #ffe5a5;
  height: 24px;
}

.farmer-item {
  width: 25%;
  background-image: url("assets/images/subitem-bg1.png");
  background-size: contain;
  background-position: center;
  margin-right: 15px;
  background-repeat: no-repeat;
  padding: 20px 0;
  font-family: "Press Start 2P";
  text-align: center;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* HTML: <div class="loader"></div> */
.loader {
  height: 60px;
  aspect-ratio: 1;
  --c:no-repeat linear-gradient(#fff 0 0);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 33.4% 33.4%;
  animation: l2 2s infinite linear;
}
@keyframes l2 {
  0%    {background-position:0   0  ,50%  0  ,0   50% ,50%  50%}
  12.5% {background-position:50% 0  ,100% 0  ,0   50% ,50%  50%}
  25%   {background-position:50% 0  ,100% 0  ,50% 50% ,100% 50%}
  37.5% {background-position:50% 0  ,100% 50%,50% 50% ,100% 100%}
  50%   {background-position:50% 50%,100% 50%,50% 100%,100% 100%}
  62.5% {background-position:50% 50%,100% 50%,0   100%,50%  100%}
  75%   {background-position:0   50%,50%  50%,0   100%,50%  100%}
  87.5% {background-position:0   0  ,50%  50%,0   50% ,50%  100%}
  100%  {background-position:0   0  ,50%  0  ,0   50% ,50%  50%}
}
</style>
