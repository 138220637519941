<template>
    <div class="footer-bar">
       <div class="container">
          <div class="row align-items-center justify-content-center">
             <!-- <div class="col-md-8">
                <ul class="footer-links">
                   <li><a href="#">Whitepaper</a></li>
                   <li><a href="faq-page.html">FAQs</a></li>
                   <li><a href="regular-page.html">Privacy Policy</a></li>
                   <li><a href="regular-page.html">Terms of Condition</a></li>
                </ul>
             </div>
             <div class="col-md-4 mt-2 mt-sm-0">
                <div
                   class="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x">
                   <div class="copyright-text">&copy; 2018 TokenWiz.</div>
                   <div class="lang-switch relative">
                      <a href="#" class="lang-switch-btn toggle-tigger">En <em class="ti ti-angle-up"></em></a>
                      <div class="toggle-class dropdown-content dropdown-content-up">
                         <ul class="lang-list">
                            <li><a href="#">Fr</a></li>
                            <li><a href="#">Bn</a></li>
                            <li><a href="#">Lt</a></li>
                         </ul>
                      </div>
                   </div>
                </div>
             </div> -->
          </div><!-- .row -->
       </div><!-- .container -->
    </div><!-- .footer-bar -->
</template>

<script>

export default {
    name: 'FooterVue',
    // props: ['chainSelected', 'chain_list', 'user', 'selectChain', 'connectWallet'],
    data(){
        return {
            navTabActive: 'swap'
        }
    },
    mounted(){
        console.log(this.$router.currentRoute.value.name)


    }
}
</script>
<style>
.mobileMenuActive {
    color: #449eff !important;
}
</style>