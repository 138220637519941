import { createRouter, createWebHistory } from 'vue-router'

import Home from './screens/Home'
import Dashboard from './screens/Dashboard'
import Lands from './screens/Lands'
import Farmers from './screens/Farmers'
import Map from './screens/Map'
import Map2 from './screens/Map2'
import Map_fix from './screens/Map_fix'

const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home'
    },
    {
        path: '/dashboard',
        component: Dashboard,
        name: 'dashboard'
    },
    {
        path: '/lands',
        component: Lands,
        name: 'lands'
    },
    {
        path: '/farmers',
        component: Farmers,
        name: 'farmers'
    },
    {
        path: '/map',
        component: Map,
        name: 'map'
    },
    {
        path: '/map2',
        component: Map2,
        name: 'map2'
    },
    {
        path: '/map_fix',
        component: Map_fix,
        name: 'map_fix'
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router
